<template>
  <div class="coach">
    <van-nav-bar title="明星教练" fixed z-index="999" placeholder style="width: 100%">
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>

    <div class="top-img-box">
      <img class="img" src="../../../assets/home/coach_top.png" alt="" />
    </div>

    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item, i) in imageList" :key="i" class="swiper-slide">
          <img
            :class="current == i ? 'item-img' : 'item-img-side'"
            class="item-img"
            :src="item.icon"
            lazy-load
            :style="dontFirstAnimation ? 'animation: none;' : ''"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="bottom-box">
      <div class="title" v-if="imageList">{{ imageList[current].name }}</div>
      <div class="tags-box" v-if="imageList">
        <span
          class="tag"
          v-for="item in imageList[current].featureList"
          :key="item"
          >{{ item }}</span
        >
      </div>
      <div class="info-box1" v-if="imageList">
        <div class="item-box2">
          <div class="title">主攻方向</div>
          <div class="detail3">{{ imageList[current].describe }}</div>
        </div>
        <div class="line"></div>
        <div class="item-box2">
          <div class="title">总体评价</div>
          <div class="detail3">{{ imageList[current].content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
 
 <script src="http://res.wx.qq.com/open/js/jweixin-1.0.0.js" type="text/javascript"></script>

<script>
import Swiper from "swiper";

import "swiper/swiper-bundle.css";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Coach_Api } from "@/api/home";
import { getLocalStorage } from "@/core/utils/local-storage";
import { Icon, NavBar } from "vant";
import { parse } from "qs";

const COMPONENT_NAME = "pull-page-slide";

export default {
  name: COMPONENT_NAME,
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  data() {
    return {
      mySwiper: null,
      swiperOption: {
        loop: false, // 无限循环
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 1,
        loopAdditionalSlides: 100,
      },
      imageList: null,
      defaultIndex: 0,
      current: 0,
      dontFirstAnimation: true,
    };
  },
  mounted() {
    this.getImageList();
  },
  // components: {
  //   Swiper,
  //   SwiperSlide,
  // },

  methods: {
    onBridgeReady() {
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        WeixinJSBridge.call("hideToolbar");
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        }
      }
    },

    initSwiper() {
      let that = this;
      this.mySwiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: false,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 1,
        observer: true,
        observeParents: true,
        on: {
          slideChange: function (event) {
            that.onChange(event.activeIndex);
          },
        },
      });
    },

    onChange(index) {
      this.dontFirstAnimation = false;
      this.current = index;
    },
    getImageList() {
      let query = parse(getLocalStorage("query"));
      this.$reqGet(Coach_Api, {
        ...query,
      }).then((res) => {
        this.imageList = res.map.items;
        this.initSwiper();
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
.coach {
  width: 375px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top-img-box {
    width: 345px;
    height: 98px;
    margin: 11px auto 0 auto;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bottom-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    .info-box1 {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 343px;
      background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);
      border-radius: 8px;
      border: 1px solid #999999;
      padding: 16px 0;
      .item-box2 {
        width: 343px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
          margin-left: 12px;
        }
        .detail3 {
          color: #333333;
          font-size: 14px;
          margin-top: 6px;
          margin: 6px 16px 0 16px;
        }
      }
      .line {
        height: 1px;
        margin: 12px 16px;
        background-color: #e5e5e5;
        width: 311px;
      }
    }
    .title {
      text-align: center;
      color: var(--theme-color);
      font-weight: 500;
      font-size: 22px;
    }
    .tags-box {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .tag {
        height: 20px;
        padding: 0 6px;
        border-radius: 4px;
        border: 1px solid var(--theme-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: var(--theme-color);
        margin: 0 4px;
      }
    }
  }
}

.swiper-container {
  margin-top: 20px;
  width: 300px;
  height: 180px;
  margin-bottom: 10px;
  overflow: visible !important;
}
.swiper-container .swiper-wrapper .swiper-slide {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

// .swiper-container .swiper-wrapper .swiper-slide img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 8px;
// }

// .swiper-container .swiper-wrapper .swiper-slide-prev {
//   height: 150px;
//   margin-top: 20px !important;
// }

// .swiper-container .swiper-wrapper .swiper-slide-prev img {
//   height: 150px !important;
//   object-fit: cover;
//   border-radius: 8px;
// }
// .swiper-container .swiper-wrapper .swiper-slide-next {
//   height: 150px;
//   margin-top: 20px !important;
// }

// .swiper-container .swiper-wrapper .swiper-slide-next img {
//   height: 150px !important;
//   object-fit: cover;
//   border-radius: 8px;
// }
// .swiper-container .swiper-wrapper .swiper-slide-active {
//   height: 180px !important;
//   width: 180px !important;
// }
.swiper-pagination {
  bottom: 15 !important;
}

#xxxFullScreen {
  background-color: white;
}
#swiper1 {
  width: 100%;
  overflow: hidden;
  padding: 0 0 10px 0;
}

.swiper-container,
.swiper-container2 {
  width: 100%;
  height: 240px;
  overflow: visible !important;
  position: relative;
}
#swiper1 .swiper-container .swiper-wrapper .swiper-slide {
  width: 180px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// /* 上一张 */
// #swiper1 .swiper-container .swiper-wrapper .swiper-slide-prev {
//   margin-top: 5px;
//   height: 150px !important;
//   width: 150px !important;
// }
// /* 下一张 */
// #swiper1 .swiper-container .swiper-wrapper .swiper-slide-next {
//   margin-top: 5px;
//   height: 150px !important;
//   width: 150px !important;
// }

/* 中间的图片 */
// .swiper-container .swiper-wrapper .swiper-slide-active {
//   height: 180px !important;
//   width: 180px !important;
// }

.image-container {
  width: 300;
  height: 350px;
  object-fit: cover;
}
// 5/7
.item-img {
  width: 160px;
  height: 224px;
  border-radius: 8px;
  animation: to-big 0.3s;
  object-fit: cover;
}

.swiper-item {
  width: 160px;
  height: 224px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-img-side {
  width: 140px;
  height: 196px;
  border-radius: 8px;
  animation: to-mini 0.3s;
  object-fit: cover;
}

.swiper-item-side {
  width: 140px;
  height: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes to-mini {
  from {
    height: 224px;
  }
  to {
    height: 196px;
  }
}
@keyframes to-big {
  from {
    height: 196px;
  }
  to {
    height: 224px;
  }
}
</style>

